body {
  background-color: #150257;
  color: white;
}

.title {
  color: white;
  margin-bottom: 1 rem;
}

.box {
  margin-top: 50px;
}

.panel {
  margin-top: 2em;
}

.panel-heading {
  background-color: black;
 
  background-color: black;
  color: white;
}

.address-box {
  padding: 3em;
}

.box .title {
  color: #333;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #150257;
}

.navbar .navbar-item {
  color: white;
}

.connect-wallet span {
  color: #e10859;
}

.connect-wallet:hover {
  background-color: #e1e1e1 !important;
}

.container.main-content {
  max-width: 1000px !important;
}

.faucet-hero-body {
  margin-top: 100px;
}

.withdraw-error {
  color: red;
}

.withdraw-success {
  color: green;
}

.header-link {
  color: orange;
}

.header-link:hover {
  color: orange;
}

.footer-link {
  color: blue;
}

.footer-link:hover {
  color: blue;
}

.boton-colorido {
  background: linear-gradient(270deg,#b0f65b,#01c6c5 22.97%,#4a7ff3 51.93%,#8171f5 83.85%,#b66df3);
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
}

h1{
  background: linear-gradient(270deg,rgba(92,7,52,0) 45%,#b0f65b 0,#01c6c5 48%,#4a7ff3 51%,#8171f5 53%,#b66df3 55%,rgba(83,0,30,0) 0) 0 100% #ffffff00 no-repeat;
  background-size: 100% 3px;
  font-size: 40px!important;
  font-weight: 600!important;
  line-height: 59px!important;
  padding-bottom: 4px;
}

.navbar-logo{
  height: 80px;
}
.logo-faucet{
  height: 200px;
}
.text-colorido {
  color: #ec539a;
}

.responsive-text {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .responsive-text {
      font-size: 14px; /* Ajusta el tamaño del texto para pantallas pequeñas */
      margin-bottom: 15px; /* Ajusta el margen para pantallas pequeñas */
  }
}
